import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import CartPage from "./pages/CartPage";
import AppProvider from "./context/AppContext";
import "./styles/global.css";
import 'swiper/swiper-bundle.css';
import TermsOfService from './pages/TermsOfService';
import Legal from './pages/Legal';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Contact from './pages/Contact';


const App = () => {
  return (
    <AppProvider>
      <Router>
        <div className="flex-col-layout">
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/product/:id" element={<ProductDetails />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/legal" element={<Legal />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </AppProvider>
  );
};

export default App;