import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";


const HeroSlider = () => {
  const slides = [
    {
      image: "/assets/hero1.jpg",
      alt: "Camera Sale",
      text: "Up to 50% Off on Cameras",
    },
    {
      image: "/assets/hero2.jpg",
      alt: "Lens Discount",
      text: "Exclusive Discounts on Lenses",
    },
    {
      image: "/assets/hero3.jpg",
      alt: "Gimbal Offer",
      text: "Best Deals on Gimbals",
    },
  ];

  return (
    <div className="relative overflow-hidden rounded-lg shadow-lg">
      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
        loop={true}
        className="h-[400px]"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="relative w-full h-full">
              <img
                src={slide.image}
                alt={slide.alt}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                <h2 className="text-white text-2xl md:text-4xl font-bold">
                  {slide.text}
                </h2>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HeroSlider;
