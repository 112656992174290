import React from "react";
import HeroSlider from "../components/HeroSlider";
import ProductCard from "../components/ProductCard";
import CategoryCard from "../components/CategoryCard";
import products from "../data/products";

const categories = [
  { name: "Cameras", image: "/assets/camera-category.webp" },
  { name: "Lenses", image: "/assets/lens-category.webp" },
  { name: "Gimbals", image: "/assets/gimbal-category.webp" },
  { name: "Accessories", image: "/assets/accessory-category.webp" },
];

const Home = () => {
  return (
    <main className="container mx-auto px-6 py-10 space-y-10">
      {/* Hero Slider Section */}
      <HeroSlider />

      {/* Categories Section */}
      <section>
        <h2 className="text-3xl font-bold mb-6 text-gray-800">Shop by Category</h2>
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
          {categories.map((category) => (
            <CategoryCard key={category.name} category={category} />
          ))}
        </div>
      </section>

      {/* Trending Products Section */}
      <section>
        <h2 className="text-3xl font-bold mb-6 text-gray-800">Trending Products</h2>
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
          {products.slice(0, 4).map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </section>

      {/* Deals Section */}
      <section className="bg-blue-100 p-6 rounded-lg">
        <h2 className="text-3xl font-bold mb-6 text-gray-800">Special Deals</h2>
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
          {products.slice(2, 6).map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </section>
    </main>
  );
};

export default Home;
