import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import products from "../data/products";
import { AppContext } from "../context/AppContext";
import { StarIcon } from '@heroicons/react/solid';

const ProductDetails = () => {
  const { id } = useParams();
  const { addToCart } = useContext(AppContext);
  const product = products.find((p) => p.id === parseInt(id));
  const [activeTab, setActiveTab] = useState('description');

  if (!product) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold text-red-600">Product Not Found</h2>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div>
          <img 
            src={product.image} 
            alt={product.name} 
            className="w-full h-auto object-cover rounded-lg shadow-lg"
          />
        </div>
        <div>
          <h1 className="text-3xl font-bold mb-4">{product.name}</h1>
          <div className="flex items-center mb-4">
            <div className="flex text-yellow-400">
              {[...Array(5)].map((_, i) => (
                <StarIcon key={i} className="h-5 w-5" />
              ))}
            </div>
            <span className="ml-2 text-gray-600">({product.reviews.length} reviews)</span>
          </div>
          <p className="text-2xl font-semibold text-blue-600 mb-4">{product.price} {product.currency}</p>
          <p className="text-gray-600 mb-4">Category: {product.category}</p>
          <p className="text-gray-700 mb-6">{product.description}</p>
          <button 
            onClick={() => addToCart(product)} 
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
          >
            Add to Cart
          </button>
        </div>
      </div>

      <div className="mb-8">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {['description', 'specifications', 'reviews'].map((tab) => (
              <button
                key={tab}
                className={`${
                  activeTab === tab
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                onClick={() => setActiveTab(tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </nav>
        </div>

        <div className="mt-8">
          {activeTab === 'description' && (
            <div>
              <h2 className="text-xl font-semibold mb-4">Product Description</h2>
              <p className="text-gray-700">{product.description}</p>
            </div>
          )}

          {activeTab === 'specifications' && (
            <div>
              <h2 className="text-xl font-semibold mb-4">Product Specifications</h2>
              <ul className="list-disc list-inside text-gray-700">
                {product.specifications.map((spec, index) => (
                  <li key={index}>{spec}</li>
                ))}
              </ul>
            </div>
          )}

          {activeTab === 'reviews' && (
            <div>
              <h2 className="text-xl font-semibold mb-4">Customer Reviews</h2>
              {product.reviews.map((review) => (
                <div key={review.id} className="mb-4 pb-4 border-b border-gray-200 last:border-b-0">
                  <div className="flex items-center mb-2">
                    <div className="flex text-yellow-400">
                      {[...Array(review.rating)].map((_, i) => (
                        <StarIcon key={i} className="h-5 w-5" />
                      ))}
                    </div>
                    <span className="ml-2 font-semibold">{review.author}</span>
                  </div>
                  <p className="text-gray-700">{review.comment}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;