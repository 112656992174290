const products = [
    {
      id: 1,
      name: "Canon EOS 90D DSLR Camera",
      price: 5499,
      currency: "AED",
      image: "/assets/camera1.png",
      category: "Camera",
      description: "The Canon EOS 90D is a versatile DSLR camera that delivers high-quality images and videos. It's perfect for both enthusiasts and professionals looking for a reliable and feature-rich camera.",
      specifications: [
        "32.5 Megapixel APS-C CMOS Sensor",
        "DIGIC 8 Image Processor",
        "UHD 4K30p & Full HD 120p Video Recording",
        "45-Point All Cross-Type AF System",
        "Dual Pixel CMOS AF with 5481 AF Points",
        "220,000-Pixel AE Metering Sensor",
        '3" 1.04m-Dot Vari-Angle Touchscreen LCD',
        "10 fps Shooting, ISO 100-25600",
        "Built-In Wi-Fi and Bluetooth",
        "EOS iTR AF, Electronic Shutter Function"
      ],
      reviews: [
        { id: 1, author: "John D.", rating: 5, comment: "Excellent camera, highly recommended!" },
        { id: 2, author: "Sarah M.", rating: 4, comment: "Great value for money, but the learning curve is steep." },
        { id: 3, author: "Mike R.", rating: 5, comment: "Professional quality images, couldn't be happier!" },
      ]
    },
    {
      id: 2,
      name: "Sony Alpha a7 III",
      price: 7899,
      currency: "AED",
      image: "/assets/camera2.webp",
      category: "Camera",
      description: "The Sony Alpha a7 III is a full-frame mirrorless camera that offers exceptional image quality, fast performance, and advanced features in a compact body.",
      specifications: [
        "24.2MP Full-Frame Exmor R BSI CMOS Sensor",
        "BIONZ X Image Processor & Front-End LSI",
        "693-Point Hybrid AF System",
        "UHD 4K30p Video with HLG & S-Log3 Gammas",
        "2.36m-Dot Tru-Finder OLED EVF",
        "3.0'' 922k-Dot Tilting Touchscreen LCD",
        "5-Axis SteadyShot INSIDE Stabilization",
        "ISO 100-51200, Extended ISO 50-204800",
        "10 fps Shooting with AF/AE Tracking",
        "Built-In Wi-Fi and NFC, Dual SD Slots"
      ],
      reviews: [
        { id: 1, author: "Emily L.", rating: 5, comment: "Amazing low-light performance and autofocus!" },
        { id: 2, author: "David K.", rating: 4, comment: "Versatile camera, but menu system can be complex." },
        { id: 3, author: "Lisa T.", rating: 5, comment: "Perfect for both photography and videography." },
      ]
    },
    {
      id: 3,
      name: "DJI Ronin-S Gimbal",
      price: 2799,
      currency: "AED",
      image: "/assets/gimbal1.webp",
      category: "Gimbal",
      description: "The DJI Ronin-S is a versatile 3-axis stabilizer for DSLR and mirrorless cameras, offering smooth and stable footage for professional videographers and filmmakers.",
      specifications: [
        "3-Axis Stabilization",
        "8.8 lb Payload",
        "One-Handed Operation",
        "Mobile Device Holder",
        "Sport Mode for Fast Movements",
        "360° Roll",
        "Focus Wheel",
        "Customizable Parameter Groups",
        "Automated Smart Shooting Modes",
        "Supports Camera Control"
      ],
      reviews: [
        { id: 1, author: "Alex M.", rating: 5, comment: "Incredible stabilization, transformed my videography!" },
        { id: 2, author: "Sophia R.", rating: 4, comment: "Bit heavy for long shoots, but results are worth it." },
        { id: 3, author: "Chris B.", rating: 5, comment: "Professional-grade gimbal at a reasonable price." },
      ]
    },
    // Add more products with detailed information...
  ];
  
  export default products;